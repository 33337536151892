<template>
  <v-dialog
    v-model="dialogMidiaCalendario"
    @click:outside="$emit('update:dialogMidiaCalendario', false)"
    @keydown.esc="$emit('update:dialogMidiaCalendario', false)"
    scrollable
    width="500"
  >
    <v-card tile>
      <v-card-title class="pa-4 font-weight-bold accent">
        Criar Calendário
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogMidiaCalendario', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-5 py-6">
        <v-form ref="form" v-model="validForm" lazy-validation>
          <v-row>
            <v-col cols="12" md="12">
              <v-autocomplete
                v-model="selectedCliente"
                :items="clientes"
                :loading="loadingClientes"
                :search-input.sync="searchClientes"
                @keyup="buscaCliente"
                dense
                hide-no-data
                item-text="nome_fantasia"
                item-value="id"
                label="Cliente"
                prepend-inner-icon="mdi-magnify"
                return-object
                required
                :rules="formRules"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="12">
              <v-autocomplete
                :disabled="!selectedCliente"
                v-model="selectedServico"
                :items="cliente_servicos"
                :loading="loadingServicos"
                dense
                hide-no-data
                item-text="servico"
                item-value="id"
                label="Serviço"
                prepend-inner-icon="mdi-magnify"
                return-object
                required
                :rules="formRules"
              ></v-autocomplete>
            </v-col>

            <!-- Valor Padrão-->
            <v-col cols="12" md="6">
              <DataField
                label="Data Início"
                :data_sync.sync="midiaCalendario.inicio"
                obrigatorio
                :data_min="minimo()"
                :data_max="midiaCalendario.fim"
              />
            </v-col>
            <v-col cols="12" md="6">
              <DataField
                label="Data Final"
                :data_sync.sync="midiaCalendario.fim"
                obrigatorio
                :data_min="midiaCalendario.inicio"
                :data_max="maximo()"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-btn @click="$emit('update:dialogMidiaCalendario', false)">
          voltar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!validForm"
          @click="createCalendario"
          color="secondary"
          class="white--text"
          depressed
        >
          Adicionar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { format, parseISO } from "date-fns";
import { postMidiaSocialCalendario } from "@/api/colaborador/midias_sociais_calendario.js";
import { inputRequired } from "@/helpers/utils.js";
import { fetchClientes } from "@/api/colaborador/clientes.js";
import { mapState } from "vuex";
import { fetchClienteServicos } from "@/api/colaborador/cliente_servicos.js";
export default {
  name: "AddCalendarioMidiaSociais",

  props: {
    dialogMidiaCalendario: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    DataField: () => import("../../../../../components/fields/DataField.vue"),
  },

  data() {
    return {
      loading: false,
      loadingClientes: false,
      midiaCalendario: {
        status: 1,
      },
      selectedCliente: null,
      selectedServico: null,
      clientes: [],
      cliente_servicos: [],
      searchClientes: "",
      validForm: true,
      loadingServicos: false,
      formRules: [inputRequired],
    };
  },

  watch: {
    selectedCliente() {
      if (this.selectedCliente) {
        this.midiaCalendario.cliente_id = this.selectedCliente.id;
        this.getClienteServicos(this.selectedCliente.id);
      }
    },
    selectedServico() {
      if (this.selectedServico) {
        this.midiaCalendario.servico_id = this.selectedServico.servico_id;
      }
    },
  },

  computed: {
    ...mapState("Usuario", {
      colaborador_id: (state) => state.usuario.colaborador_id,
    }),
  },

  methods: {
    getClienteServicos(cliente_id) {
      this.loadingServicos = true;
      return fetchClienteServicos(cliente_id)
        .then((response) => {
          this.cliente_servicos = response;
          this.loadingServicos = false;
        })
        .catch(() => {
          this.loadingServicos = false;
        });
    },
    maximo() {
      if (this.midiaCalendario.inicio) {
        let dia = parseISO(this.midiaCalendario.inicio);
        let anoC = dia.getFullYear();
        let mesC = dia.getMonth();
        let diaMax = new Date(anoC, mesC + 1, 0);

        return format(diaMax, "yyyy-MM-dd");
      }
    },
    minimo() {
      if (this.midiaCalendario.fim) {
        let dia = parseISO(this.midiaCalendario.fim);
        let anoC = dia.getFullYear();
        let mesC = dia.getMonth();
        let diaMax = new Date(anoC, mesC, 1);
        return format(diaMax, "yyyy-MM-dd");
      }
    },
    getClientes() {
      this.loading = true;
      fetchClientes(`?colaborador_id=${this.colaborador_id}`)
        .then((response) => {
          this.clientes = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    buscaCliente(event) {
      if (this.searchClientes) {
        if (this.searchClientes.length >= 2 && event.key != "Backspace") {
          this.clientes = [];
          this.getClientes(`?search=${this.searchClientes}`);
        }
      }
    },

    createCalendario() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.midiaCalendario.created_id = this.colaborador_id;
        postMidiaSocialCalendario(this.midiaCalendario)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Calendário Criado com sucesso!");
              this.$emit("fetch-calendarios");
              this.$emit("update:dialogMidiaCalendario", false);
              this.loading = false;
            }
          })
          .catch(() => {
            console.log("error");
            this.loading = false;
          });
      }
    },
  },

  mounted() {
    this.getClientes();
  },
};
</script>

<style lang="scss"></style>
